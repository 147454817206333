<template>
  <div>
    <basic-container>
    <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @row-update="handleUpdate"
                 @row-del="handleDel">
            <template slot="status" slot-scope="scope">
                <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                @change="change(scope.row)">
                </el-switch>
            </template>
        <template slot="menuLeft">
          <importBtn
              :outApi="this.proApis.MAINTENANCEORDEREXPORT"
              :innerAPI="this.proApis.MAINTENANCEORDERIMPORT"
              :API="this.proApis.MAINTENANCEORDERIMPORTTEMPLATE"
              @refresh="refreshData"
          ></importBtn>
        </template>
        <template slot="menuRight">
            <el-input v-model="nameOrCode" :placeholder="$t('Maintenance name or code')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
        </template>
        <template slot="menu">
            <!-- <el-button type="text" size="mini" icon="el-icon-discover" style="color:red;">禁用</el-button> -->
        </template>
    </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list,add,edit,remove} from "@/api/maintain"
import importBtn from "@/components/importExport/importBtn";
import {machineModelList,getMechinaAll} from "@/api/config";
export default {
  components:{
    importBtn
  },
    data() {
        return {
            nameOrCode: "",
            departmentId: "1",
            page: {
                pageSize: 20,
                pagerCount:5,
                total: 10,
                pageCurrent: 1
            },
            disabled:true,
            tableData:[],
            tableOption: {
                delBtn: true,
                menuWidth: 200,
                selection: true,
                tip: false,
                columnBtn: false,
                dialogFullscreen: true,
                addBtnText: this.$t('ADD'),
                delBtnText: this.$t('DELETE'),
                editBtnText: this.$t('Edit'),
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                column: [{
                    label: this.$t('SELECT DEVICE'),
                    editDisabled: true,
                    dicData: [],
                    hide: true,
                    type: "select",
                    prop: "machineId",
                    props: {
                        label: "name",
                        value: "id"
                    },
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE DEVICE NAME'),
                        trigger: "blur"
                    }]
                }, {
                    label: this.$t('EQUIPMENT NAME'),
                    display: false,
                    prop: "maintenanceName",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE DEVICE NAME'),
                        trigger: "blur"
                    }]
                }, {
                    display: false,
                    label: this.$t('EQUIPMENT CODE'),
                    prop: "maintenanceCode"
                }, {
                    label: this.$t('START TIME'),
                    width: 150,
                    prop: "beginTime",
                    valueFormat: "yyyy-MM-dd hh:mm:ss",
                    type: "datetime",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE START TIME'),
                        trigger: "blur"
                    }]
                }, {
                    label: this.$t('END TIME'),
                    prop: "endTime",
                    valueFormat: "yyyy-MM-dd hh:mm:ss",
                    type: "datetime"
                }, {
                    label: this.$t('REMARK'),
                    prop: "remarks"
                }, {
                    label: this.$t('REPLACE ACCESSORIES'),
                    type: "radio",
                    prop: "isReplace",
                    dicData: [{
                        label: this.$t('True'),
                        value: 1
                    }, {
                        label: this.$t('False'),
                        value: 0
                    }]
                }, {
                    label: this.$t('CREATION TIME'),
                    valueFormat: "yyyy-MM-dd hh:mm:ss",
                    display: false,
                    prop: "createTime"
                }, {
                    label: this.$t('CREATE USER'),
                    display: false,
                    prop: "createUsersName"
                }, {
                    label: this.$t('CONFIRMATION TIME'),
                    valueFormat: "yyyy-MM-dd hh:mm:ss",
                    prop: "confirmTime",
                    type: "datetime"
                },
                //  {
                //label: "确认用户",
                //display: false,
                //valueFormat: "yyyy-MM-dd hh:mm:ss",
                //prop: "confirmUserName"
                //  },
                {
                    label: this.$t('COMPANY'),
                    display: false,
                    hide: true,
                    prop: "corporationId"
                }]
            }
        }
    },
    created() {
      getMechinaAll().then(res => {
        this.tableOption.column[0].dicData = res.data.data;
      });
      this.list();
    },
        methods: {
            currentChange(pageCurrent) {
                this.page.pageCurrent = pageCurrent;
                this.list();
            },
            sizeChange(pageSize) {
                this.page.pageCurrent = 1;
                this.page.pageSize = pageSize;
                this.list();
            },
            refreshData() {
                this.list();
            },
            handleDel(row) {
                this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: 'warning'
                }).then(() => {
                    remove(row.id).then((res) => {
                        if(res.data.code=="0000"){
                            this.$message({
                            message: "删除成功",
                            type: "success"
                            });
                        }
                        this.list();
                    })
                })
            },
            handleUpdate(row, index, done) {
                edit(row).then((res) => {
                    if(res.data.code=="0000"){
                        this.$message({
                        message: "修改成功",
                        type: "success"
                        });
                    }
                    this.list();
                    done();
                })
            },
            handleSave(row, done) {
                add(row).then((res) => {
                    if(res.data.code=="0000"){
                        this.$message({
                        message: "新增成功",
                        type: "success"
                        });
                    }
                    this.list();
                    done();
                })
            },
            change(row) {
                changeStatus(row)
            },
            selectionChange(list) {
                console.log(list);
                list != "" ? this.disabled = false : this.disabled = true;
            },
            refreshChange() {
                this.list();
            },
            list() {
                list(this.page.pageCurrent, this.page.pageSize, this.nameOrCode).then(res => {
                    this.page.total = res.data.data.total;
                    this.tableData = res.data.data.items;
                    this.tableData.forEach(v => {
                        v.status = v.status;
                    })
                })
            },
            envText: function () {
                return this.env
            }
        }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>